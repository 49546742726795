html {
  scroll-behavior: smooth;
}

section {
  padding: 10px;
  padding-left: 20%;
  padding-right: 20%;
}

div.section-content {
  padding: 10px;
  padding-left: 12%;
}

section#about {
  background-color: #E6CDD0;
  /* background-color: rgb(205, 247, 205); */
}

section#contact {
  background-color: #EAE7E5;
}

section#work {
  background-color: #CEC7C4;
}

section#projects {
  background-color: #F3F4F6;
}

section#read {
  background-color: #E2DFC6;
} 

section {
  background-color: white !important;
}

.section-header {
  padding: 2px;
  font-weight: bold;
  background-color: rgb(241, 241, 241);
}

p,
td,
li,
a {
  /* line-height: 1.3; */
  /* font-family: "Leitura News","Palatino","Georgia","Times New Roman",serif; */
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  color: #052d3f;
  /* font-weight: 400; */
}

a:hover {
  background-color: rgb(255, 232, 27);
}

b {
  background-color: rgb(255, 248, 188);
  font-weight: bold;
  padding: 1px;
}

.no-highlight {
  background-color: unset;
}

.no-highlight:hover {
  background-color: unset;
}

b>a:hover {
  background-color: rgb(255, 232, 27);
}


@media only screen and (max-width: 600px) {
  div.section-content {
    padding: unset;
  }

  section {
    padding-left: 5%;
    padding-right: 5%;
  }

}